import { IGatsbyImageData } from 'gatsby-plugin-image'
import React, { ReactNode } from 'react'
import { trackClick } from '../../analyticsUtils'
import { responsiveBackgroundImage } from '../../styles/helpers'
import Block from '../primitives/Block'
import Box from '../primitives/Box'
import Row from '../primitives/Row'
import Stack from '../primitives/Stack'
import Button from '../system/Button'

type HeroButton = {
  text: string
  href: string
}

type PlainProps = {
  tag?: string
  title: ReactNode
  content?: ReactNode
  primaryButton?: HeroButton
  secondaryButton?: HeroButton | null
  customCta?: ReactNode
}

type Props = PlainProps & {
  backgroundImage: Pick<IGatsbyImageData, 'backgroundColor' | 'images'>
}

const contentMaxWidth = 540
const desktopButtonMaxWidth = 280

const HeroBlock = (props: Props) => {
  return (
    <Box
      // This is used by Header to change the color when scrolling past the header.
      className="hero-block"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      display="flex"
      alignItems="center"
      justifyContent="center"
      color="white"
      minHeight={{ xs: 640, md: 720 }}
      css={`
         ${responsiveBackgroundImage(
           props.backgroundImage.images.fallback?.srcSet || '',
           props.backgroundImage.backgroundColor || '',
         )}
          }
      `}
    >
      <HeroBlockPlain {...props} />
    </Box>
  )
}

export const HeroBlockPlain = ({
  tag,
  title,
  content,
  primaryButton = { text: 'Apply ', href: '/startup-signup' },
  secondaryButton = {
    text: 'Start free trial',
    href: 'https://app.swarmia.com/signup/',
  },
  customCta,
}: PlainProps) => {
  return (
    <Block paddingTop={{ xs: 92, sm: 64 }} paddingBottom={64}>
      <Stack alignItems="center">
        {tag ? <Box.img src={tag} /> : null}
        <Box.h1 marginTop={40} font="h1" maxWidth={790} textAlign="center">
          {title}
        </Box.h1>
        {content ? (
          <Box
            marginTop={32}
            font="large"
            textAlign="center"
            maxWidth={contentMaxWidth}
          >
            {content}
          </Box>
        ) : null}
        {customCta ?? (
          <Row
            marginTop={48}
            space={16}
            flexDirection={{ xs: 'column', md: 'row' }}
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              onClick={trackClick('HeroBlock - Primary Button')}
              href={primaryButton.href}
              variant="primary"
              size="large"
              width="100%"
              maxWidth={{ xs: contentMaxWidth, md: desktopButtonMaxWidth }}
            >
              {primaryButton.text}
            </Button>
            {secondaryButton ? (
              <Button
                onClick={trackClick('HeroBlock - Secondary Button')}
                href={secondaryButton.href}
                size="large"
                width="100%"
                maxWidth={{ xs: contentMaxWidth, md: desktopButtonMaxWidth }}
              >
                {secondaryButton.text}
              </Button>
            ) : null}
          </Row>
        )}
      </Stack>
    </Block>
  )
}

export default HeroBlock
