import { css } from '@styled-system/css'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image'
import React, { ReactNode, useState } from 'react'
import BubbleIcon from '../../assets/images/home/icons/bubble.svg'
import ChartIcon from '../../assets/images/home/icons/chart.svg'
import CompassIcon from '../../assets/images/home/icons/compass.svg'
import DiamondIcon from '../../assets/images/home/icons/diamond-hand.svg'
import EyeIcon from '../../assets/images/home/icons/eye.svg'
import ManagerIcon from '../../assets/images/home/icons/manager.svg'
import MountainIcon from '../../assets/images/home/icons/mountain.svg'
import { responsiveScale } from '../../styles/helpers'
import Block from '../primitives/Block'
import Box from '../primitives/Box'
import Row from '../primitives/Row'
import Stack from '../primitives/Stack'
import Br from '../system/Br'
import PullRequest from '../../assets/images/icons/pull-request.svg'
import Button from '../system/Button'
import { Quote, quotes } from './QuoteBlock'
import HeartIcon from '../../assets/images/product/overview/heart.svg'

export function PersonasBlockStartup() {
  const data = useStaticQuery(graphql`
    query {
      leaders: file(relativePath: { eq: "images/personas/developers.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      managers: file(relativePath: { eq: "images/personas/managers.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      platform: file(relativePath: { eq: "images/personas/leaders.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const tabs = [
    {
      key: '1',
      label: 'Developers',
      content: (
        <TabContent
          subtitle="Swarmia for developers"
          title={
            <>
              Get more focus time <Br /> and automate manual tasks
            </>
          }
          bullets={[
            {
              icon: PullRequest,
              text: <>Get your pull requests through faster</>,
            },
            {
              icon: DiamondIcon,
              text: <>Automate feedback loops</>,
            },
            {
              icon: ManagerIcon,
              text: <>Start better conversations based on real data</>,
            },
          ]}
          image={getImage(data.leaders)!}
          quote={quotes.silvia}
        />
      ),
    },
    {
      key: '2',
      label: 'Engineering managers',
      content: (
        <TabContent
          subtitle="Swarmia for engineering managers"
          title={
            <>
              Measure the right things
              <Br /> at every level of the organization
            </>
          }
          bullets={[
            {
              icon: CompassIcon,
              text: <>Boost productivity with actionable metrics</>,
            },
            {
              icon: BubbleIcon,
              text: <>Complement system metrics with surveys</>,
            },
            {
              icon: HeartIcon,
              text: <>Improve developer experience with the right tooling</>,
            },
          ]}
          image={getImage(data.managers)!}
          quote={quotes.carsten}
        />
      ),
    },
    {
      key: '3',
      label: 'Engineering leaders',
      content: (
        <TabContent
          subtitle="Swarmia for engineering leaders"
          title={<>Take surprises out of feature delivery</>}
          bullets={[
            {
              icon: MountainIcon,
              text: <>Balance engineering investments</>,
            },
            {
              icon: EyeIcon,
              text: <>Create audit-ready software capitalization reports</>,
            },
            {
              icon: ChartIcon,
              text: <>Give better board updates</>,
            },
          ]}
          image={getImage(data.platform)!}
          quote={quotes.alex4}
        />
      ),
    },
  ]
  const [activeTab, setActiveTab] = useState(tabs[0].key)

  return (
    <Block paddingTop={32} paddingBottom={48}>
      <Stack space={responsiveScale(48)}>
        <Box
          display="flex"
          justifyContent="center"
          position="relative"
          // @ts-expect-error this works
          marginX={{ xs: -20, md: 0 }}
        >
          <Box
            position="absolute"
            right={0}
            top={0}
            width={20}
            height="100%"
            css={`
              background: linear-gradient(
                270deg,
                rgba(255, 255, 255, 0.4) 0%,
                rgba(255, 255, 255, 0) 100%
              );
            `}
          />
          <Row
            space={{ xs: 8, sm: 12 }}
            overflowX="auto"
            paddingBottom={4}
            paddingX={{ xs: 20, md: 0 }}
            css={`
              scrollbar-width: none;

              &::-webkit-scrollbar {
                display: none;
              }
            `}
          >
            {tabs.map(tab => (
              <TabButton
                key={tab.key}
                onClick={() => setActiveTab(tab.key)}
                active={tab.key === activeTab}
              >
                {tab.label}
              </TabButton>
            ))}
          </Row>
        </Box>

        <Box>{tabs.find(tab => tab.key === activeTab)?.content ?? null}</Box>
      </Stack>
    </Block>
  )
}
function TabButton({
  children,
  active,
  onClick,
}: {
  children: ReactNode
  active: boolean
  onClick: () => void
}) {
  return (
    <Button
      fontSize={{ xs: 16, md: 18 }}
      variant="hoverHighlight"
      height={{ xs: 40, md: 50 }}
      backgroundColor={active ? 'blue' : 'white'}
      color={active ? 'white' : 'black700'}
      hoverProps={
        active
          ? { backgroundColor: 'blue', color: 'white' }
          : { color: 'black900' }
      }
      paddingX={32}
      borderRadius={9999}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

type BulletPoint = {
  icon: string
  text: ReactNode
}

function TabContent({
  subtitle,
  title,
  bullets,
  image,
  quote,
}: {
  subtitle: string
  title: ReactNode
  bullets: BulletPoint[]
  image: IGatsbyImageData
  quote: Quote
}) {
  return (
    <Row space={24} flexDirection={{ xs: 'column', md: 'row' }}>
      <Box
        flex={1}
        borderRadius={{ xs: 16, md: 32 }}
        padding={{ xs: 24, sm: 64 }}
        borderColor="black100"
      >
        <Box
          font="small"
          textTransform="uppercase"
          fontWeight={700}
          color="dataPurple"
          paddingBottom={24}
          lineHeight="20px"
          display={{ xs: 'none', sm: 'block' }}
        >
          {subtitle}
        </Box>
        <Box
          font="h3"
          paddingBottom={{ xs: 24, md: 32 }}
          fontSize={{ xs: 24, md: 32 }}
        >
          {title}
        </Box>
        <Stack space={24}>
          {bullets.map(bullet => (
            <Row key={bullet.icon} space={20} alignItems="center">
              <Box.img
                loading="lazy"
                width={32}
                height={32}
                src={bullet.icon}
              />
              <Box font="large" fontSize={{ xs: 16, md: 20 }} lineHeight="130%">
                {bullet.text}
              </Box>
            </Row>
          ))}
        </Stack>
      </Box>
      <Box flex={1}>
        <Stack space={{ xs: 24, md: 32 }}>
          <Box
            isolation="isolate"
            overflow="hidden"
            borderRadius={{ xs: 16, md: 24 }}
          >
            <GatsbyImage image={image} alt="" />
          </Box>
          <Box paddingLeft={32} paddingBottom={{ xs: 0, md: 12 }}>
            <SmallQuote quote={quote} />
          </Box>
        </Stack>
      </Box>
    </Row>
  )
}

function SmallQuote({ quote }: { quote: Quote }) {
  const { quote: text, name, title, photo } = quote
  return (
    <Row space={24}>
      <Stack space={24}>
        <Box
          font="medium"
          fontWeight={700}
          color="blue900"
          maxWidth={{ xs: '100%', md: 'largeBlurb' }}
          css={css`
            &::before {
              content: '“';
              width: 10px;
              margin-left: -10px;
              display: inline-block;
            }
            &::after {
              content: '”';
              width: 20px;
              /* Prevent flowing to second row*/
              margin-right: -20px;
              display: inline-block;
            }
            text-wrap: balance;
          `}
        >
          {text}
        </Box>
        <Row space={16} alignItems="center">
          <Box.img loading="lazy" width={64} height={64} src={photo} />
          <Stack position="relative">
            <Box font="small" fontWeight={700}>
              {name}
            </Box>
            <Box font="small">{title}</Box>
          </Stack>
        </Row>
      </Stack>
    </Row>
  )
}
