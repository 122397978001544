import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import ProductCarousel from './ProductCarousel'

const StartupCarousel = () => {
  const images = useStaticQuery(graphql`
    query {
      flow: file(
        relativePath: { eq: "images/carousel-images/collaboration.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 868)
        }
      }

      impact: file(relativePath: { eq: "images/carousel-images/flow.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 868)
        }
      }

      quality: file(
        relativePath: { eq: "images/carousel-images/delivery.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 868)
        }
      }

      collaboration: file(
        relativePath: { eq: "images/carousel-images/improvement.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 868)
        }
      }

      automate: file(
        relativePath: { eq: "images/carousel-images/improvement.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 868)
        }
      }

      surveys: file(
        relativePath: { eq: "images/carousel-images/surveys.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 868)
        }
      }
    }
  `)

  return (
    <ProductCarousel
      items={[
        {
          id: 'decode',
          title: 'Unblock the flow',
          description:
            'Identify and eliminate common teamwork anti-patterns like siloing or too much work in progress.',
          image: getImage(images.flow),
        },
        {
          id: 'measure',
          title: 'Measure productivity',
          description:
            'Track healthy productivity metrics, like DORA and SPACE, and drill into aggregates to find and fix the root causes of issues.',
          image: getImage(images.impact),
        },
        {
          id: 'accelerate',
          title: 'Get your PRs through faster',
          description:
            'See all your PRs at a glance and get real-time notifications about PRs waiting for your review.',
          image: getImage(images.quality),
        },
        {
          id: 'automate',
          title: 'Adopt working agreements',
          description:
            'Set team-wide working agreements to follow industry best practices and track relevant numeric targets.',
          image: getImage(images.automate),
        },
        {
          id: 'devex',
          title: 'Improve DevEx with surveys',
          description:
            'Combine system metrics with developer feedback so you know exactly what to improve and how.',
          image: getImage(images.surveys),
        },
      ]}
    />
  )
}

export default StartupCarousel
