import React from 'react'
import FAQWrapper from './FAQWrapper'

const FAQStartup = () => {
  return (
    <FAQWrapper>
      <details>
        <summary>What is Swarmia startup program?</summary>
        <p>
          The Swarmia startup program offers early-stage companies a discounted
          way to drive engineering effectiveness with Swarmia. Startups
          affiliated with one of the partners in the Swarmia Startup Program get
          a 50% discount for their first year.
        </p>
      </details>
      <details>
        <summary>Who can apply?</summary>
        <p>
          Startups new to Swarmia with 10-50 developers affiliated with a
          Swarmia startup program partner can apply.
        </p>
      </details>
      <details id="investor-members">
        <summary>How can I apply?</summary>
        <p>
          <a href="https://forms.gle/5n4fQ82L4LFW4aEY7">Fill out this form</a>{' '}
          to apply.
        </p>
      </details>
      <details>
        <summary>Is my investor a member of Swarmia startup program?</summary>
        <p>
          Below is the list of all program partners:
          <br />
          <a href="https://startupcenter.aalto.fi/">Aalto Startup Center</a>
          <br />
          <a href="https://www.alphalist.com">Alphalist</a>
          <br />
          <a href="https://www.alven.co">Alven</a>
          <br />
          <a href="https://www.antler.co/">Antler</a>
          <br />
          <a href="https://www.byfounders.vc/">byFounders</a>
          <br />
          <a href="https://www.cavalry.vc">Cavalry Ventures</a>
          <br />
          <a href="https://www.dig.ventures">Dig Ventures</a>
          <br />
          <a href="https://www.fiveelms.com/">Five Elms Capital</a>
          <br />
          <a href="https://www.founderpass.com">Founderpass</a>
          <br />
          <a href="https://www.identity.vc">Identity VC</a>
          <br />
          <a href="https://www.illusian.org">Illusian Founder Office</a>
          <br />
          <a href="https://www.lifelineventures.com">Lifeline Ventures</a>
          <br />
          <a href="https://www.moltenventures.com/">Molten Ventures</a>
          <br />
          <a href="https://www.nea.com">New Enterprise Associates: NEA</a>
          <br />
          <a href="https://www.northzone.com">Northzone</a>
          <br />
          <a href="https://www.supercell.com">Supercell</a>
          <br />
          <a href="https://www.susaventures.com">Susa Ventures</a>
          <br />
          <a href="https://www.underline.vc">Underline Ventures</a>
          <br />
          <a href="https://www.wave.ventures">Wave Ventures</a>
          <br />
          <a href="https://deals.ycombinator.com">Y Combinator</a>
          <br />
          <br /> To apply to become a Swarmia startup program partner, email us
          at <a href="mailto:startups@swarmia.com">startups@swarmia.com</a>
        </p>
      </details>
      <details>
        <summary>
          Our organization is larger than 50 developers. Can I still apply?
        </summary>
        <p>
          Startups with 50 or fewer developers can apply to the program. If your
          team is larger, please{' '}
          <a href="https://www.swarmia.com/demo/">contact our sales team</a> or{' '}
          <a href="https://app.swarmia.com/signup/">
            {' '}
            start a free 14-day trial.
          </a>
        </p>
      </details>
      <details>
        <summary>
          I’m an investor and would like to offer Swarmia to my portfolio.
        </summary>
        <p>Email us at startups@swarmia.com.</p>
      </details>
      <details>
        <summary>
          Who qualifies for the free plan of Swarmia and what’s included in it?
        </summary>
        <p>
          <a href="https://app.swarmia.com/signup/">The free plan</a> is
          intended for companies with fewer than 10 software developers, and it
          includes most features available in the Standard plan.
          <br />
          You don’t need to be associated with a startup program partner to
          start using the free plan.
        </p>
      </details>
    </FAQWrapper>
  )
}

export default FAQStartup
